import Vue from "vue";
import Router from "vue-router";
import Home from '@/pages/Home';
import About from '@/pages/About';

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      name: "home",
      meta: {
        layout: "no-sidebar",
        title: "Motive Classification"
      },
      component: require("@/pages/Home.vue").default // load sync home
    },
    {
      path: "/about",
      name: "about",
      meta: {
        layout: "no-sidebar",
        title: "Automating Implicit Motive Coding"
      },
      component: () => import("@/pages/About.vue")
    },
    {
      path: "/blog",
      name: "blog",
      meta: {
        title: "Blog"
      },
      component: () => import("@/pages/BlogPosts.vue")
    },
    {
      path: "/blog/:id",
      name: "blogpost",
      meta: {
        layout: "no-sidebar",
        title: "Blog"
      },
      component: () => import("@/pages/BlogPost.vue")
    },
    {
      path: "*",
      name: "404*",
      component: require("@/pages/404.vue").default // load sync home
    }
  ]
});
