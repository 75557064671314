<template>
  <div id="app">
    <component :is="layout">
      <router-view :key="componentKey"/>
    </component>
  </div>
</template>

<script>
const default_layout = "default";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    }
  },
    created() {
      // nothing defined here (when this.$route.path is other than "/")
      console.log(this.$route, this.$route.meta.layout);
    },
    updated() {
      // something defined here whatever the this.$route.path
      console.log(this.$route, this.$route.meta.layout);
    },
  data() {
    return {
      componentKey: 0,
    };
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    }
  }
  // watch: {
  //     '$route':{
  //       handler: (to, from) => {
  //         document.title = to.meta.title || 'Your Website'
  //       },
  //        immediate: true
  //     }
  //   },
};
</script>
