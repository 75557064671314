<template>
  <headerbar>
    <div id="headbar">
      <h1 style="text-align:center; ">{{ pageTitle }}</h1>
      <hr/>
    </div>
  </headerbar>
</template>

<script>
import RenderlessPagesList from "../components/RenderlessPagesList";
import blogPageListProps from "../components/blogPageListProps";

export default {
  name: "headBar",
  props: {
    blogPageListProps
  },
  components: {
    RenderlessPagesList
  },
  data() {
    return {
      articlepath: this.$route.path,//`blog/${this.id}`, // eslint-disable-line global-require, import/no-dynamic-require
      pageTitle: this.$route.meta.title
    };
  }
};
</script>

<style>
headerbar {
  text-align: center;
  margin: auto;
}
</style>
