<script>
import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar";
import HeaderBar from "@/components/HeaderBar";

export default {
  components: {
    NavBar,
    HeaderBar,
    FooterBar
  }
};
</script>

<template>
  <div>
    <nav-bar />
    <header-bar :key="$route.fullPath"/>
    <div class="container section">
      <div>
        <slot />
      </div>
    </div>

    <footer-bar />
  </div>
</template>

<style>
html, body {
  height: 100%;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: auto;
  /* margin-top: 60px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px; */
  flex: 1 0 auto;
  width: 100%;
  min-width: auto;
}

body {
  display: flex;
  flex-direction: column;
  /* margin: 20px; */
}
.container{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
  /* width: 80%; */
  min-width: auto;
  margin: auto;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-content: center;
}
.column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-basis: 100%;
  flex: 1;
}

tag {
  color: #2c3e50;
  margin: auto;
}

</style>
