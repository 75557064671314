<template>
  <div class="home">
    <!-- set progressbar -->
    <vue-progress-bar></vue-progress-bar>
    <div class="container col-md-10 mx-auto">
      <form v-on:submit.prevent="addNewTask">
        <label for="tasknameinput">Test the ELECTRA model:</label>
        <input v-model="taskname" type="textarea" id="tasknameinput" class="form-control" placeholder="Enter Sentence or Paragraph">
        <button type="submit" class="btn btn-success btn-block">
          Submit
        </button>
      </form>
      <table class="table">
        <tr>
          <td class="font-weight-bold text-left" style="text-align:center;">Text</td>
          <td class="font-weight-bold text-left" style="text-align:center;">Pow&nbsp;</td>
          <td class="font-weight-bold text-left" style="text-align:center;">Ach&nbsp;</td>
          <td class="font-weight-bold text-left" style="text-align:center;">Aff&nbsp;</td>
        </tr>
        <tr v-for="(txt, index) in textClassify" :key="index">
          <td class="text-left">{{txt.textinput}}</td>
          <td class="text-left" style="text-align:center;">{{txt.pow}}</td>
          <td class="text-left" style="text-align:center;">{{txt.ach}}</td>
          <td class="text-left" style="text-align:center;">{{txt.aff}}</td>
        </tr>
      </table>
      <disclaimer class="text-left" style="font-size:0.9em;"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import Disclaimer from '@/components/disclaimer.txt'

let ROOT_PATH = 'https://implicitmotives.com'
export default {
  components: { Disclaimer },
  data () {
    return {
      textClassify: [],
      id: '',
      taskname: '',
      isEdit: false,
      logo: ROOT_PATH + require('@/assets/WordcloudAch.png')
    }
  },
  metaInfo() {
    return {
      meta: [
          // Twitter Card
          {name: 'twitter:card', content: 'summary'},
          {name: 'twitter:title', content: 'Implicit Motives text classifier'},
          {name: 'twitter:type', content: 'website'},
          {name: 'twitter:description', content: 'A sentence classifier for implicit motives nPow, nAch, nAff.'},
          // image must be an absolute path
          {name: 'twitter:image', content: this.logo},
          {name: 'twitter:image:alt', content: 'Wordcloud of Ach sentences'},
          // Facebook OpenGraph
          {property: 'og:title', content: 'Implicit Motives text classifier'},
          {property: 'og:site_name', content: 'Implicit Motives'},
          {property: 'og:type', content: 'website'},
          {property: 'og:image', content:  this.logo},
          {property: 'og:description', content: 'A sentence classifier for implicit motives nPow, nAch, nAff.'},
          {property: 'og:image:alt', content: 'Wordcloud of Ach sentences'},
          {property: 'og:image:type', content: 'image/png'},
          {property: 'og:image:width', content: '800'},
          {property: 'og:image:height', content: '800'}
      ]
    }
  },
  mounted () {
    this.getTasks()
  },
  methods: {
    start () {
      this.$Progress.start()
    },
    set (num) {
      this.$Progress.set(num)
    },
    increase (num) {
      this.$Progress.increase(num)
    },
    decrease (num) {
      this.$Progress.decrease(num)
    },
    finish () {
      this.$Progress.finish()
    },
    fail () {
      this.$Progress.fail()
    },
    getTasks () {
      // axios({ method: 'GET', url: '/api/tasks' }).then(
      axios.get('/api/tasks').then(
        result => {
          console.log(result.data)
          this.textClassify = result.data
        },
        error => {
          console.error(error)
        }
      )
    },
    addNewTask () {
      this.$Progress.start()
      axios.post('/api/task',
        { textinput: this.taskname })
        .then(res => {
          this.taskname = ''
          this.getTasks()
          console.log(res)
          this.$Progress.finish()
        },
        res => {
          this.$Progress.fail()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
};
</script>
<!--
<style>
.container{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style> -->
