<template>
  <navbar>
  <div id="nav-bar">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/blog">Blog</router-link>
  </div>
  </navbar>
</template>

<script>
export default {
  name: "navBar",
  data() {
    return {
      open: false
    };
  }
};
</script>

<style>
navbar {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin: 20px
}
</style>
