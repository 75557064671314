<template>
  <div class="about">
    <aboutext class="text-left" style="font-size:1.2em;"/>
  </div>
</template>

<script>
  import Aboutext from '@/components/aboutext.txt'
  export default {
    components: { Aboutext }
  }
</script>

<style>

.aboutext {
    /* display: flex; */
    /* height: 100%; */
    margin-bottom: 20px;
    padding: 20px;
    hyphens: auto;
    /* align-content: center; */
    /* flex-direction: column; */
    /* justify-content: center; */
}
</style>
