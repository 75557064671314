<template>
   <div id="content-wrap">
   <!-- Footer -->
   <afooter class="a-footer">
     <div class="text-center p-4">
      Copyright © 2020-2023
      <a class="text-reset fw-bold" style="text-decoration: none" href="https://encodingai.com">Encoding A.I.</a>
     </div>
   </afooter>
   <!-- Footer -->
 </div>
</template>

<script>
export default {
  name: "aFooter",
  // data() {
  //   return {
  //     footerText: '',
  //   }
  }
// }
</script>

<style>
afooter {
  position: fixed;
  bottom: 0;
  margin-top: -20px;
  left: 0;
  right: 0;
  height: 14;
  width: 100%;
  /* flex-shrink: 0; */
  flex: 0 0 30px;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  padding: 4px;
  text-align: center;
  background: white;
  color: #2c3e50;
  margin-top: auto;
}
</style>
