<script>
const posts = {};

const req = require.context('../posts/', false, /\.md$/);
req.keys().forEach((key) => {
  posts[key] = req(key);
})

import {
  compact,
  flatMap,
  uniq,
  each,
  get,
  filter,
  some,
  includes,
  sortBy
} from "lodash"
import { format, toDate, parseISO } from "date-fns"

export default {
  name: 'blog',
  data() {
    return {
      posts: []
    }
  },
  props: {
    byTags: {
      default() {
        return []
      },
      type: [Array, String]
    },
    byCategories: {
      default() {
        return []
      },
      type: [Array, String]
    },
    byPaths: {
      default() {
        return []
      },
      type: [Array, String]
    },
    notTags: {
      default() {
        return []
      },
      type: [Array, String]
    },
    notCategories: {
      default() {
        return []
      },
      type: [Array, String]
    },
    notPaths: {
      default() {
        return []
      },
      type: [Array, String]
    },
  },
  computed: {
    filteredPosts() {
      this.filterPosts()
      return this.posts.filter(post => post.status === 'publish').sort((a, b) => {
          return new Date(b.created) - new Date(a.created);
        })
    },
  },
  mounted() {
  },
  methods: {
    articles() {
      const articleArray = [];
      Object.keys(posts).forEach((key) => {
        const article = posts[key];
        article.path = key.replace('./', '').replace('.md', '');
        articleArray.push(article);
      });

      articleArray.filter(post => post.status === 'publish').sort((a, b) => {
          return new Date(b.created) - new Date(a.created);
        });
      return articleArray;
    },
    filterPosts() {
      this.setPosts()
      // this.filterOutByPaths()
      // this.filterByPaths()
      this.filterByCategories()
      this.filterByTags()
      this.filterOutByCategories()
      this.filterOutByTags()
      this.sortByMostRecent()
    },
    filterByTags() {
      this.filterIncludes("byTags", "post.tags")
    },
    filterByCategories() {
      this.filterIncludes("byCategories", "post.categories")
    },
    filterByPaths() {
      this.filterStartsWith("byPaths", "post.path")
    },
    filterOutByTags() {
      this.filterIncludes("notTags", "post.tags", true)
    },
    filterOutByCategories() {
      this.filterIncludes("notCategories", "post.categories", true)
    },
    filterOutByPaths() {
      this.filterIsNot("notPaths", "post.path")
    },
    setPosts() {
      // window.console.log(this.articleArray),
      this.posts = this.articles()
    },
    filterIncludes(byWhat, byKey, exclude = false) {
      if (!get(this, byWhat).length) return

      let self = this

      this.posts = filter(this.posts, function(post) {
        let yesNo = some(get(post, byKey), postKeyValue =>
          includes(get(self, byWhat), postKeyValue)
        )
        return !exclude ? yesNo : !yesNo
      })
    },
    filterStartsWith(byWhat, byKey) {
      if (!get(this, byWhat).length) return

      let self = this

      this.posts = filter(this.posts, function(post) {
        let postKeyValues = get(self, byWhat)
        if (typeof postKeyValues === "string") {
          postKeyValues = [postKeyValues]
        }
        let yesNos = []
        each(postKeyValues, value =>
          yesNos.push(get(post, byKey).startsWith(value))
        )
        return some(yesNos)
      })
    },
    filterIsNot(byWhat, byKey) {
      if (!get(this, byWhat).length) return

      let self = this

      this.posts = filter(this.posts, function(post) {
        let postKeyValues = get(self, byWhat)
        if (typeof postKeyValues === "string") {
          postKeyValues = [postKeyValues]
        }
        let yesNos = []
        each(postKeyValues, value => yesNos.push(get(post, byKey) === value))
        return !some(yesNos)
      })
    },
    sortByMostRecent() {
      this.posts = sortBy(this.posts, [(post) => { return format(toDate(parseISO(post.created)), 'S'); }]).reverse()
    },
    categories() {
      return compact(uniq(flatMap(this.posts, "post.categories"))).sort()
    },
    tags() {
      return compact(uniq(flatMap(this.posts, "post.tags"))).sort()
    },
    formatAnchor(string) {
      return string.toLowerCase().replace(/ /g, "-")
    },
    formatDate(created) {
      return format(toDate(parseISO(created)), 'P')// zz')
    }
  },
  render() {
    return this.$scopedSlots.default({
      posts: this.filteredPosts,
      tags: this.tags,
      categories: this.categories,
      formatDate: this.formatDate,
      formatAnchor: this.formatAnchor
    })
  }
}
</script>
