<template>
  <div class="sidebar">
    <RenderlessPagesList :byTags="byTags" :byCategories="byCategories" :notTags="notTags" :notCategories="notCategories">
      <div slot-scope="{ posts, formatDate }">
        <div v-for="post in posts" :key="post.created" v-if="post.status == 'publish'">
          <p>
          <small class="text-grey">
            {{ formatDate(post.created) | dateParse('MM/DD/YYYY') | dateFormat('DD/MM/YYYY') }}
             <!-- &bull; -->
          </small>
          <br>
          <router-link :to="`/blog/${ post.path }`" style="color:#2c3e50; text-decoration:none">{{ post.title }}
          </router-link>
          </p>
        </div>
      </div>
    </RenderlessPagesList>
  </div>
</template>

<script>
import RenderlessPagesList from "../components/RenderlessPagesList"
import blogPageListProps from "../components/blogPageListProps"
export default {
  name: "sideBar",
  props: blogPageListProps,
  components: {
    RenderlessPagesList
  },
  methods: {
    orderedItems(items) {
      return items.sort((a, b) => { return b.created - a.created;});
    }
  }
}
</script>
