import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueGtag from "vue-gtag";
import store from './store';
import Default from "./layouts/Default.vue";
import NoSidebar from "./layouts/NoSidebar.vue";
import VueProgressBar from 'vue-progressbar';
import Meta from 'vue-meta';
import Ads from 'vue-google-adsense';
require('../node_modules/bootstrap/dist/css/bootstrap.css');

Vue.component("default-layout", Default);
Vue.component("no-sidebar-layout", NoSidebar);

Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat);
Vue.use(require('vue-script2'));
Vue.use(Ads.Adsense);
Vue.use(Meta);

Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '10px'
});

Vue.use(VueGtag, {
  config: { id: "UA-165730719-1" }
}, router);

Vue.filter('striphtml', function (value) {
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});

new Vue({
  router,
  store,
  // components: { App },
  // template: '<App/>',
  render: h => h(App)
}).$mount('#app', true)
